import React from "react"
import JsIcon from "../../static/logos/js.svg"
import ReactNativeIcon from "../../static/logos/ReactNative_logo.svg"
import GatsbyIcon from "../../static/logos/Gatsby_logo.svg"
import NextJSIcon from "../../static/logos/NextJS_logo.svg"
import SvelteIcon from "../../static/logos/Svelte_logo.svg"
import WordPressIcon from "../../static/logos/WordPress_logo.svg"
import StrapiIcon from "../../static/logos/StrapiCMS_logo.svg"
import DatoCMSIcon from "../../static/logos/DatoCMS_logo.svg"
import SanityIcon from "../../static/logos/Sanity_logo.svg"
import AirTableIcon from "../../static/logos/AirTable_logo.svg"
import AWSIcon from "../../static/logos/AWS_logo.svg"
import NetlifyIcon from "../../static/logos/Netlify_logo.svg"
import VercelIcon from "../../static/logos/Vercel_logo.svg"
import FirebaseIcon from "../../static/logos/Firebase_logo.svg"
import SurgeIcon from "../../static/logos/Surge.sh_logo.svg"
import IntegromatIcon from "../../static/logos/Integromat_logo.svg"
import ZapierIcon from "../../static/logos/Zapier_logo.svg"
import N8nIcon from "../../static/logos/n8n_logo.svg"

export const myTechnologies = [
    {
        name: 'build',
        tools: [<div><JsIcon /><br/></div>, <div><ReactNativeIcon /><br/></div>, <div><GatsbyIcon /><br/></div>, <div><NextJSIcon /></div>, <div><SvelteIcon /></div>]
    },
    {
        name: 'data',
        tools: [<div><WordPressIcon /></div>, <div><StrapiIcon /></div>, <div><DatoCMSIcon /></div>, <div><SanityIcon /></div>, <div><AirTableIcon/></div>]
    },
    {
        name: 'deploy',
        tools: [<div><AWSIcon /></div>, <div><NetlifyIcon /></div>, <div><VercelIcon /></div>, <div><FirebaseIcon /></div>, <div><SurgeIcon /></div>]
    },
    {
        name: 'automation',
        tools: [<div><IntegromatIcon /></div>, <div><ZapierIcon /></div>, <div><N8nIcon /></div>]
    }
];
