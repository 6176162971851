import React, { useEffect, useState } from "react"
import styled from 'styled-components';
import { useTranslation } from "react-i18next"
import { gsap } from 'gsap';
import { StaticImage } from "gatsby-plugin-image";
import Layout from '../layout/layout';
import Seo from "../components/seoComponent";
import Container from '../components/Container';
import SectionHeader from "../components/Typography/SectionHeader"
import { myTechnologies } from "../data/myTechnologies"
import { websiteUrl } from "../data/navItems"

const InfoWrapper = styled.section`
    padding: var(--firstSectionPadding);
    color: var(--textTitle); 
     
    .about_me {
        display: grid;
        grid-template-columns: 30% auto;
        gap: 16px;
        
        & > div {
            opacity: 0;
        }
        
        p {
            margin: 10px 0;
        }
    }
    
    @media (max-width: 768px) {
        .about_me {
            display: unset;
        }
        
        .gatsby-image-wrapper {
            float: left;
            width: 34%;
            margin: 0 10px 0 0;
        }
    }
`;

const TechnologiesWrapper = styled.div`
    padding: 3rem 0;
    background: var(--blogPostList);
    margin-top: 4rem;
    border-radius: 1rem;
    --bg: #e9e9e9;
    box-shadow: 0 15px 30px -25px rgba(0,0,0,.25);
    opacity: 0;
    
    .categories_list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        width: min-content;
        margin: 0 auto;
        background: transparent;
        border-bottom: 1px solid var(--primary);
        
        @media (max-width: 768px) {
            grid-template-columns: 50% 50%;
            width: 100%;
            border: none;
        }
        
        button {
            padding: .6em 1em;
            color: var(--textNormal);
            background: none;
            border: none;
            font-size: inherit;
            transition: color .3s ease-out, background .3s ease-out;
            
            &:focus {
                outline: 1px solid var(--primary);
                outline-offset: -1px;
            }
        }
        
        .activeSkill {
            background: var(--primary);
            color: white;
            
            &:focus {
                outline: 1px solid var(--primary);
                outline-offset: -2px;
            }
        }
    }
    
    .tech_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        min-height: 350px;
        
        
        & > div {
            display: flex;
            align-items: center;
            margin: 5px 0;
            opacity: 0;
        }  
        
        svg {
            height: 28px;
            width: auto;
            fill: var(--textLighter)!important;
            filter: grayscale(1);
        }
        
        p {
            opacity: 0;
        }
    }
    
    .tech_list.data > div {
        margin: 10px 0;
    }
`;

const AboutPage = ({ location, pageContext }) => {

    pageContext.createdAt = "2021-08-30";

    const url = `${websiteUrl + location.pathname}`;
    const { t } = useTranslation();

    const [skill = 'build', setSkill] = useState();

    useEffect(() => {
          gsap.set(['.about_me > div:first-child, .about_me > div:nth-child(2), .technologies'], { ease: 'power4.out', duration: 1.5})
          gsap.fromTo('.about_me > div:first-child', { x: -100, autoAlpha: 0 }, { x: 0, autoAlpha: 1 })
          gsap.fromTo('.about_me > div:nth-child(2)', { x: 100, autoAlpha: 0 }, { x: 0, autoAlpha: 1 })
          gsap.fromTo('.technologies', { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1, delay: .25 })
    }, []);

    useEffect(() => {
          gsap.fromTo('.tech_list div', { autoAlpha: 0, x: 20}, {autoAlpha: 1, x: 0, ease: 'elastic.out(1, 0.3)', duration: 1.5, stagger: .15})
    }, [skill]);

    return (
        <Layout>
            <Seo
                title="Info"
                description="Poznajmy się, mam na imię Adam. Jestem web developerem od 2018 roku, sprawnie poruszającym się w dziedzinach marketingu, designu oraz SEO."
                pageUrl={url}
                pageName={location.pathname.slice(1,-1)}
            />
            <InfoWrapper>
            <Container noPadding>
                <SectionHeader dark>
                    <span className="underlined">
                        {t('info_page.header_line_1')}
                    </span>
                    , {' '}
                    {t('info_page.header_line_2')}
                    <span role="img" aria-label="emoji"> 👋</span>
                </SectionHeader>
                <div className="about_me">
                    <StaticImage
                        src="../../static/me.jpg"
                        alt=""
                        width={300}
                        height={450}
                        formats={["auto", "webp", "avif"]}
                    />
                    <div>
                        <p>{t('info_page.paragraph1')}</p>
                        {/*<p>Szybko odkryłem, że nie ma sensu tworzyć koła na nowo i zacząłem wykorzystywać zewnętrze rozwiązania API, aby dodawać rozmaite funkcjonalności do tworzonych projektów w szybki sposób. W rezultacie zainteresowałem się konwencją Jamstack, która dziś przewodzi mi w tworzeniu stron internetowych, a o której piszę więcej <a className="underscored" href="/panodstrony.pl">tutaj</a></p>*/}
                        {/*<p>Osobiście uwielbiam nowinki technologiczne i lubię poznawać kolejne, ciekawe narzędzia. Szczególnie interesuje mnie tematyka decentralizacji i technologii blockchain, która pomału rewolucjonizuje sposób, w jaki przechowujemy cyfrowe dane. Jestem także wielkim fanem automatyzacji, która może oszczędzić nam sporo czasu, który poświęcamy na proste i powtarzalne czynności.</p>*/}
                        <p>{t('info_page.paragraph2')}</p>
                        <p>{t('info_page.paragraph3')}</p>
                        <p>{t('info_page.paragraph4')}</p>
                    </div>
                </div>
                <TechnologiesWrapper className="technologies">
                    <SectionHeader dark center notAnimatable>
                        {t('info_page.pref_tech')}
                    </SectionHeader>
                    <div className="categories_list">
                        {myTechnologies.map(category => (
                                <button
                                    key={category.name}
                                    className={skill === category.name ? 'activeSkill' : 'skill_category'}
                                    onClick={() => {setSkill(category.name)}}
                                >
                                    <span>
                                        {category.name}
                                    </span>
                                </button>
                        ))}
                    </div>
                    <div className={`tech_list ` + skill}>
                        {skill === 'build' && myTechnologies[0].tools}
                        {skill === 'data' && myTechnologies[1].tools}
                        {skill === 'deploy' && myTechnologies[2].tools}
                        {skill === 'automation' && myTechnologies[3].tools}
                    </div>
                  </TechnologiesWrapper>
            </Container>
            </InfoWrapper>
        </Layout>
)};

export default AboutPage;
